import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderModule } from '@x/common/loader/loader.module';
import { RoutingModule } from '@x/ecommerce-shop/app/core/routing/routing.module';
import { ShopLayoutComponent } from '@x/ecommerce-shop/app/core/shop-ui/components/layout/shop-layout/shop-layout.component';

@NgModule({
  declarations: [ShopLayoutComponent],
  imports: [CommonModule, RoutingModule, LoaderModule],
  exports: [ShopLayoutComponent],
})
export class ShopLayoutModule {}
