<ng-content select="x-shop-header, [xHeader]"></ng-content>
<ng-content select="x-breadcrumb"></ng-content>

<section class="body">
  <ng-content select="[xBody]"></ng-content>
</section>

<footer>
  <ng-content select="x-shop-footer"></ng-content>
  <ng-content select="[xFooter]"></ng-content>
</footer>

<x-loader class="shop-layout-loader" *ngIf="rc.routeTransition$ | async">
  <ng-container xLoaderContent>
    <ng-content select="[xLoaderContent]"></ng-content>
  </ng-container>
</x-loader>
