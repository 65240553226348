import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingContextService } from '@x/ecommerce-shop/app/core/routing/routing-context.service';

@Component({
  selector: 'x-shop-layout',
  templateUrl: './shop-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-shop-layout',
  },
})
export class ShopLayoutComponent {
  constructor(public rc: RoutingContextService) {}
}
